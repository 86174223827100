import { Controller } from 'stimulus';
import Swiper, { A11y, EffectFade, Pagination } from 'swiper';

Swiper.use([A11y, EffectFade, Pagination]);

export default class extends Controller {
  static targets = ['slider', 'pagination'];

  connect() {
    this.initSwiper();
  }

  disconnect() {
    this.destroySwiper();
  }

  /**
   * initSwiper
   * - Initialize the Swiper instance
   */
  initSwiper() {
    this.swiper = new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      slidesPerGroup: 1,
      autoHeight: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      centeredSlides: true,
      on: {
        slideChange: () => {
          this.updateAriaNotification();
        },
      }
    });

    // Hack-fix for Swiper pagination not updating after a swipe gesture
    this.swiper.on('slideChangeTransitionEnd', () => {
      // Swiper pagination does not hold the correct index when swiping when there are multiple Swiper instances
      // Short-circuit if pagination does not exist
      if (!this.swiper.pagination) return;
      this.swiper.pagination.render();
      this.swiper.pagination.update();
    });

    // Update the aria-notification element with the initial slide details
    this.updateAriaNotification();
  }

  /**
   * updateAriaNotification
   * - Manually updates the swiper-notification element with slide change details
   */
  updateAriaNotification() {
    // Find the swiper-notification element specific to this Swiper instance
    const notification = this.sliderTarget.querySelector('.swiper-notification');

    // If it exists
    if (notification) {
      // Get the current slide index and total slides
      const currentSlideIndex = this.swiper.realIndex + 1;
      const totalSlides = this.swiper.slides.length;

      // Update the innerText of the notification element
      notification.innerText = `Slide ${currentSlideIndex} of ${totalSlides} is active.`;
    }
  }

  /**
   * destroySwiper
   * - Destroy the Swiper instance, if it exists
   */
  destroySwiper() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
  }
}
